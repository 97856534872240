@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.backgroundBlur {
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  backdrop-filter: blur(10px);
}

.modalContentContainer {
  border: 1px solid red;
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (--viewportMedium) {
    flex-basis: 488px;
    min-height: auto;
    height: auto;
    margin-top: 8vh;
  }
}

.scrollLayer {
  @apply --marketplaceModalRootStyles;

  /* Add default background color to avoid bouncing scroll showing the
 page contents from behind the modal. */
  background-color: var(--matterColorLight);

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (--viewportMedium) {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }
}

.isClosed {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.modalTitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    @media (--viewportLarge) {
      margin-left: 16px;
    }
}

.button {
  @apply --marketplaceTinyFontStyles;
  width: 380px;
  margin-top: 16px;
  margin-bottom: 0;
  cursor: pointer;
  border: none;
  padding: 16px 0;
  border-radius: var(--borderRadius);
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  &:hover {
      background-color: var(--marketplaceColorHover);
   }
}

.cancelButton {
  padding: 12px 0;
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  border: 2px solid var(--matterColorGreyButtonBorder);
  &:hover {
      background-color: var(--matterColorLight);
      border: 2px solid var(--marketplaceColor);
   }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
  }
}

.nextArrowWrapper {
  position: absolute;
  right: 50px;
  top: 21px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadiusCircle);
  &:hover {
    background-color: var(--marketplaceColorSecondary)
  }
}
.prevArrowWrapper {
  position: absolute;
  left: 50px;
  top: 21px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadiusCircle);
  &:hover {
    background-color: var(--marketplaceColorSecondary)
  }
}

.discountInputLabel {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
}

.discountFieldContainer {
  position: relative;
  max-width: 500px;
  cursor: pointer;
}

.discountFieldArrow {
  position: absolute;
  right: 5px;
  bottom: 11px;
}
